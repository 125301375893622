import getLocale from "@invitato/helpers/dist/getLocale";

export default {
  to: getLocale('Nama', 'Name'),
  desc: getLocale('Keterangan', 'Description'),
  important: getLocale('Penting', 'Important'),
  importantDesc: getLocale(
    'Silakan tunjukkan QRCode disamping kepada usher yang bertugas di lokasi acara.',
    'Please show the QR code to enter the event venue.',
  ),
};

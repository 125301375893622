export const BG_AYAT = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/ayat.jpg?updatedAt=1700556300069`;
export const BG_COVER = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/cover.jpg?updatedAt=1700556300141`;
export const BG_PATTERN = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/pattern.jpg?updatedAt=1700556300318`;
export const BG_WEDDING_DETAIL = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/wedding.jpg?updatedAt=1700556303368`;
export const BG_WELCOME = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/welcome.jpg?updatedAt=1700556303900`;
export const BG_WISHLIST = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/wishes.jpg?updatedAt=1700556303390`;
export const BG_YOUTUBE = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/youtube.jpg?updatedAt=1700556300072`;
export const BG_RSVP = `https://ik.imagekit.io/invitatoid/template-sintabayu/flower_l5jzY_mxI.jpg?updatedAt=1637986567900`;
export const BG_LOVESTORY = BG_COVER;
export const BG_LOVE_JOURNEY = BG_COVER;
export const BG_FILTER = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/filter.jpg?updatedAt=1700633215501`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/dekstop.jpg?updatedAt=1700556300325`;

export const IMG_MAN = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/man.jpg?updatedAt=1700556300334`;
export const IMG_GIRL = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/girl.jpg?updatedAt=1700556300344`;

export const COVER_YOUTUBE = `https://lh3.googleusercontent.com/-PzMzRP-ufY0/YZ-BUy08YNI/AAAAAAAAABQ/FrptBB-lxVkmzxl3GeIS5eBONvxZcVxwwCLcBGAsYHQ/flower.jpg`;
export const COVER_GALLERY_1 = `https://ik.imagekit.io/bxwgcojbe/winna-kesvian/gallery.jpg?updatedAt=1700556300095`;
// fill this constants below if you need more 1 cover gallery
export const COVER_GALLERY_2 = ``;
export const COVER_GALLERY_3 = ``;

// constant for song
export const BACKSOUND_MUSIC_URL = `https://res.cloudinary.com/invitato/video/upload/v1637994418/template-song/LIR-ILIR%20by%20Fajar%20Play%20Acoustic.mp3`;

// INVITATO GLOBAL ASSETS
export const INVITATO_LOGO = `https://lh3.googleusercontent.com/-zCJo2-mf_4A/YZ-Ovftkr0I/AAAAAAAAABw/s2v0OGabAmA_0YtFckCFBNBWnFB_NSydQCLcBGAsYHQ/image.png`;
export const INVITATO_COVID = `https://ik.imagekit.io/invitatoid/template-sintabayu/covid_tBpbXxZiG5v.jpeg?updatedAt=1637987099354`;
export const INVITATO_FAVICON = `https://ik.imagekit.io/masansgaming/Mutiara_Imam/favicon_hT9G7Of7N.png?ik-sdk-version=javascript-1.4.3&updatedAt=1675605821981`;

// Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;

// Don't change with CDN Image
import ImgQRCover from '@/images/qrcover.jpg';
export const IMG_QR_COVER = ImgQRCover;